import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Callout = makeShortcode("Callout");
const Checklist = makeShortcode("Checklist");
const Column = makeShortcode("Column");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Tout = makeShortcode("Tout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Contributing Content`}</h1>
    <InfoBlock mdxType="InfoBlock">
      <p><img parentName="p" {...{
          "src": "/images/illustrations/Squid-4.png",
          "alt": "Content"
        }}></img></p>
      <Box mdxType="Box">
        <h2>{`Create new content, edit existing content, and suggest improvements.`}</h2>
        <p>{`Designers, writers, editors, and other skilled contributors are encouraged to help with existing efforts and propose new ones.`}</p>
        <p>{`Community-contributed content helps ensure up-to-date resources for learning about MakerDAO, Maker, and Dai.`}</p>
      </Box>
    </InfoBlock>
    <Callout secondary icon="dai" mdxType="Callout">
      <p>{`Get paid in Dai to create content for Comm-Dev.`}</p>
    </Callout>
    <h2>{`Requirements`}</h2>
    <p>{`Community-contributed content provides up-to-date resources for learning about MakerDAO, Maker, and Dai.`}</p>
    <p>{`Designers, Writers, Editors, and other skilled contributors are encouraged to help with current content creation efforts and propose new ones.`}</p>
    <p>{`Content contributors should be:`}</p>
    <Checklist mdxType="Checklist">
      <p>{`Excited to grow the MakerDAO community and its resources.`}</p>
      <p>{`Familiar with GitHub, Markdown, Google Docs, and HackMD.`}</p>
      <p>{`Skilled at communication.`}</p>
      <p>{`Effective writers.`}</p>
      <p>{`Collaborative and used to working with a team.`}</p>
    </Checklist>
    <h3>{`How to Contribute`}</h3>
    <p>{`Anyone can edit any page on this site.`}</p>
    <p>{`We believe open-source contributions are the key to a healthy community.`}</p>
    <Column mdxType="Column">
      <Box mdxType="Box">
        <h2>{`Write New Content`}</h2>
        <p>{`We welcome videos, documents, pictures.`}</p>
        <p><a parentName="p" {...{
            "href": "/contribute/content/writing-style-guide/"
          }}>{`How to write like Maker`}</a></p>
        <p><a parentName="p" {...{
            "href": "/contribute/content/contributor-tools/"
          }}>{`Contributing tools Guide`}</a></p>
      </Box>
      <Box mdxType="Box">
        <h2>{`Make Edits and Improvements`}</h2>
        <p>{`If you spot any mistakes, or if you would like to add any information, feel free to hit Edit and make changes via GitHub. All edits go through a review process before being merged into the community repository.`}</p>
        <p><a parentName="p" {...{
            "href": "/contribute/content/reviewer-guide/"
          }}>{`How to review like Maker`}</a></p>
        <p><a parentName="p" {...{
            "href": "/contribute/content/using-github/"
          }}>{`How to Use Github`}</a></p>
      </Box>
    </Column>
    {
      /* <Column>
      <Tout>
      ### Educational Content
      Educational content helps convert MakerDAO novices into experts while improving everyone’s technical understanding.
      <List>
      [Guide to Vaults](/learn/vaults/)
      [Guide to DAI](/learn/Dai/)
      [Guide to Governance](/learn/governance/)
      [Awesome-MakerDAO](https://awesome.makerdao.com)
      </List>
      </Tout>
      <Tout>
      ### Reference Content
      Reference content provides documented details about a given domain to help improve Maker’s processes.
      <List>
      [Governance Call Notes](/contribute/governance_and_risk_meetings/take-notes/)
      [Writing Style Guide](/contribute/content/writing-style-guide/)
      [Development Grants](/funding/development-grants)
      Even this page...
      </List>
      </Tout>
      </Column> */
    }
    {
      /* <Callout icon="lightbulb">
      Be sure to look over our list of [open bounties](https://github.com/makerdao/community/projects/2?card_filter_query=label%3A%22help+wanted%22)  first to make sure your concept is unique.  Many ideas already offer bounties for contributors.
      </Callout> */
    }
    <h3>{`Payment`}</h3>
    <p>{`You can earn Dai for any content you create that gets approved. Please reach out privately to discuss payment and compensation. `}</p>
    <p>{`Don't be afraid to ask someone in the community chat for help with submitting an invoice.`}</p>
    <h2>{`Ready to Get Started?`}</h2>
    <p>{`Introduce yourself to the community to let us know you want to contribute.`}</p>
    <Aligner center mdxType="Aligner">
      <Button to="https://chat.makerdao.com/channel/community-development" icon="rocketchat" mdxType="Button">Community Chat</Button>
    </Aligner>
    {
      /* ## Content Resources
      These resources should help with writing and editing content for the community:
      <List>
      </List> */
    }
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <h3>{`Publishing Process`}</h3>
        <p>{`Learn how new content goes from an idea to becoming a published page on our site.`}</p>
        <p><a parentName="p" {...{
            "href": "/resources/technical-content/publishing-to-repo-process/"
          }}>{`Publishing Content`}</a></p>
      </Box>
      <Box mdxType="Box">
        <h3>{`Using GitHub`}</h3>
        <p>{`We use GitHub to manage content on our site. Learn how it works.`}</p>
        <p><a parentName="p" {...{
            "href": "/contribute/content/using-github/"
          }}>{`How to Use Github`}</a></p>
      </Box>
    </Tout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      